<template>
  <div class="betinfoliks">
    <router-link tag="button" to="/front/sportsbethistory" :class="{'active_link':$store.state.currentBetHistoryIndex === 1}"> 스포츠
    </router-link>
    <router-link tag="button" to="/front/minigamebethistory" :class="{'active_link':$store.state.currentBetHistoryIndex === 2}">미니게임
    </router-link>
    <router-link tag="button" to="/front/casinoslotbetinfo" :class="{'active_link':$store.state.currentBetHistoryIndex === 3}">카지노
    </router-link>
<!--    <router-link tag="button" to="/front/tgamebethistory"-->
<!--                 :class="{'active_link':$store.state.currentBetHistoryIndex === 4}">토큰게임-->
<!--    </router-link>-->

    <!--                        <router-link class="bw4" to="/reachcasino_betinfo" tag="button">Reach 카지노</router-link>-->
  </div>
</template>

<script>

export default {
  name: "BetHistoryLinks",
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.betinfoliks {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: .1rem 0;
  margin-bottom: .5rem;
}

.betinfoliks button {
  flex: 1;
  height: 3.8rem;
  background: linear-gradient(180deg, var(--minigamecompbuttonbg01), var(--minigamecompbuttonbg02));
  color: #fff9ff;
  margin: 0 .1rem;
}

.active_link {
  background: linear-gradient(180deg, #d93124, #d93124) !important;
}

@media screen and (max-width: 1024px) {
  .betinfoliks button {
  }
}

</style>