<template>
    <div>


        <div class="mybet" v-for="(betinfo,index) in betList" :key="index">
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_NOT">

              <div class="match_warpper" v-for="(item,index2) in betinfo.betInfoGameList">
                <div class="info">
                  <div class="league">
                    <div class="gametypeandtime">
                      <div class="gtimg">
                        <img src="../../../../assets/images/icon/type/1/football-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER" alt="">
                        <img src="../../../../assets/images/icon/type/1/baseball-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_BASEBALL" alt="">
                        <img src="../../../../assets/images/icon/type/1/basketball-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL" alt="">
                        <img src="../../../../assets/images/icon/type/1/volleyball-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL" alt="">
                        <img src="../../../../assets/images/icon/type/1/hockey-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_HOCKEY" alt="">
                        <img src="../../../../assets/images/icon/type/1/esport-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_ESPORTS" alt="">
                        <img src="../../../../assets/images/icon/type/1/tennis-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_TENNIS" alt="">
                        <img src="../../../../assets/images/icon/type/1/usa-football-ico.png" v-if="item.game.league.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL" alt="">
                      </div>
                      <div class="time">
                        {{item.game.startTime|datef('MM월DD일 HH:mm')}}
                      </div>
                    </div>
                    <div class="limg"><img alt="" :src="item.game.league.imageUrl"></div>
                    <div class="lname"  :style="{color:item.game.league.leagueColor}">{{item.game.league.leagueName}}</div>
                    <div class="result">
                       <span class="score"
                             v-if="item.game.gameResult !== sportsConst.GAME_RESULT_CANCELED
                                && item.game.gameResult !== sportsConst.GAME_RESULT_SPECIAL
                                && item.game.gameResult !== sportsConst.GAME_RESULT_WATING">
                                    {{item.game.homeTeamScore}}:{{item.game.awayTeamScore}}
                      </span>
                      <span class="score text_cancel" v-if="item.game.gameResult === sportsConst.GAME_RESULT_CANCELED">취소</span>
                      <span class="score text_cancel"  v-if="item.game.gameResult === sportsConst.GAME_RESULT_SPECIAL">적특</span>
                      <span class="score text_ing" v-if="item.game.gameResult === sportsConst.GAME_RESULT_WATING">대기중</span>

                      <span class="betres text_win" v-if="item.betGameResult === sportsConst.BET_RESULT_WIN">적중</span>
                      <span class="betres text_lose" v-if="item.betGameResult === sportsConst.BET_RESULT_LOSE">낙첨 </span>
                      <span class="betres text_cancel" v-if="item.betGameResult === sportsConst.BET_RESULT_SPECIALCASE">적특 </span>
                      <span class="betres text_cancel" v-if="item.betGameResult === sportsConst.BET_RESULT_CANCEL">취소</span>
                    </div>
                  </div>

                  <div class="match">
                    <div class="type">
                      <font v-if="item.game.kind == sportsConst.GAME_KIND_1X2">승무패</font>
                      <font v-if="item.game.kind == sportsConst.GAME_KIND_HANDICAP">핸디캡</font>
                      <font v-if="item.game.kind == sportsConst.GAME_KIND_OVERUNDER">오버언더</font>
                    </div>
                    <div class="home" :class="{'active_sports':item.selectedVal == sportsConst.WAY_HOME}">
                      <span class="teamname spelingcut" v-html="item.game.homeTeamName"></span>
                      <span class="homeodd">
                        <i class="fa fa-h-square hicon lefticon" v-if="item.game.kind === sportsConst.GAME_KIND_HANDICAP"></i>
                        <i class="fa fa-arrow-up ouicon2 lefticon" v-if="item.game.kind === sportsConst.GAME_KIND_OVERUNDER"></i>
                        <span class="om">
                          {{item.homeTeamOdds}}
                        </span>
                      </span>
                    </div>
                    <div class="mid draw" v-if="item.game.kind === sportsConst.GAME_KIND_1X2 && item.drawOdds > 0" :class="{'active_sports':item.selectedVal == sportsConst.WAY_DRAW}">
                      {{item.drawOdds}}
                    </div>
                    <div class="mid draw vs" v-if="item.game.kind === sportsConst.GAME_KIND_1X2 && item.drawOdds <= 0" >vs</div>

                    <div class="mid draw txthandicap" v-if="item.game.kind === sportsConst.GAME_KIND_HANDICAP">{{item.handicapVal}}</div>
                    <div class="mid draw txthandicap" v-if="item.game.kind === sportsConst.GAME_KIND_OVERUNDER">{{item.overunderVal}}</div>

                    <div class="away" :class="{'active_sports':item.selectedVal == sportsConst.WAY_AWAY}">
                      <span class="awayodd">
                            <i class="fa fa-h-square hicon righticon" v-if="item.game.kind === sportsConst.GAME_KIND_HANDICAP"></i>
                           <i class="fa fa-arrow-down ouicon1 righticon" v-if="item.game.kind === sportsConst.GAME_KIND_OVERUNDER"></i>
                           <span class="om">
                             {{item.awayTeamOdds}}
                           </span>
                      </span>
                      <span class="teamname spelingcut" v-html="item.game.awayTeamName"></span>
                    </div>
                  </div>
                </div>
              </div >
            </div>
            <!--인플레이-->
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_YES">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList"
                     :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>
                            <span class="inplay_team_name">{{betInfoGame.game.homeTeamName}} vs {{betInfoGame.game.awayTeamName}}</span>
                        </div>
                        <ul>
                            <li>
                                <table class="table" style="text-align: center">
                                    <tr>
                                        <th>승부타임</th>
                                        <th>구분</th>
                                        <th>선택항목</th>
                                        <th>선택배당</th>
                                        <th>베팅결과</th>
                                    </tr>
                                    <tr>
                                        <td>{{betInfoGame.ito1}}</td>
                                        <td>{{betInfoGame.ito2}}</td>
                                        <td class="inplaybet_selected">{{betInfoGame.ito3}}</td>
                                        <td>{{betInfoGame.selectedOdds}} <span v-if="betInfoGame.score !== undefined"
                                                                               class="score">{{' ('+betInfoGame.score+')'}}</span>
                                        </td>
                                        <td>
                                        <span class="text_ing" v-if="betInfoGame.betGameResult === sportsConst.BET_RESULT_WAITING">
                                                대기
                                              </span>
                                            <span class="text_win" v-if="betInfoGame.betGameResult ===  sportsConst.BET_RESULT_WIN">
                                                적중
                                         </span>
                                            <span class="text_lose" v-if="betInfoGame.betGameResult ===  sportsConst.BET_RESULT_LOSE">
                                            낙첨
                                          </span>
                                            <span class="text_special" v-if="betInfoGame.betGameResult ===  sportsConst.BET_RESULT_SPECIALCASE">
                                             적특
                                          </span>
                                            <span class="text_cancel" v-if="betInfoGame.betGameResult ===  sportsConst.BET_RESULT_CANCEL">
                                             취소
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="betInfo">
                <span class="chk">

                  <input v-if="position === sportsConst.ENABLE" type="checkbox"
                         @click="checkBetId(betinfo.id)" style="margin-right: 5px;width: 5rem">
   <img src="../../../../assets/images/afront/btn/베팅취소하기.png" alt=""
        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING
                       && betinfo.isinplay === sportsConst.INPLAY_NOT && position === sportsConst.ENABLE"
        @click="cancelBet(betinfo.id)">
<!--                     <a href="javascript:void(0)" class="cancelbet"-->
<!--                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING-->
<!--                       && betinfo.isinplay === sportsConst.INPLAY_NOT && position === sportsConst.ENABLE"-->
<!--                        @click="cancelBet(betinfo.id)">취소</a>-->

                </span>
                <span class="time">
                  <i>{{betinfo.createTime|datef('MM-DD HH:mm')}}</i>
                </span>
                <span
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING" class="text_ing">결과대기</span>
                <span class="text_win"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_WIN">당첨</span>
                <span class="text_lose"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_LOSE">낙첨</span>
                <span class="text_special"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_SPECIALCASE">적특</span>
                <span class="text_cancel"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_CANCEL">취소
                            </span>


                <span class="odds">배당: {{betinfo.betOddsTotal}}</span>
                <span class="betCash">베팅금: {{betinfo.betCash|comma}}</span>
                <span class="wincash">
                예상당첨:
                {{(betinfo.totalCash)|comma}}&nbsp;<i v-if="betinfo.bonusCash > 0"
                                                      style="font-style: normal">(+{{betinfo.bonusCash|comma}})</i>
                </span>


            </div>
        </div>

      <div class="history_btns"
           v-if="position === sportsConst.ENABLE">
        <img src="../../../../assets/images/afront/btn/베팅선택문의.png" alt="" @click="add2customercenter"
             v-if="position">
        <img src="../../../../assets/images/afront/btn/베팅선택삭제.png" alt="" @click="deleteBet"
             v-if="position">
        <img src="../../../../assets/images/afront/btn/베팅전체삭제.png" alt="" @click="deleteAllBetInfo"
             v-if="position">

<!--        <button class="ask" @click="add2customercenter"-->
<!--                v-if="position">-->
<!--          선택문의-->
<!--        </button>-->
<!--        <button class="seldel"-->
<!--                @click="deleteBet" v-if="position">-->
<!--          선택삭제-->
<!--        </button>-->
<!--        <button class="delall"  @click="deleteAllBetInfo"-->
<!--                v-if="position">-->
<!--          전체삭제-->
<!--        </button>-->

      </div>
    </div>

</template>

<script>
    import {cancelBet, deleteAllBet, deleteBet} from "@/network/sportsBetRequest";
    import sportsConst from "@/common/sportsConst";

    export default {
        name: "SportsBetHistoryDetails",
        components: {},
        data() {
            return {
                sportsConst,
                betIds: [],
            }
        },
        props: {
            betList: {
                type: Array,
                default: []
            },
            position: {
                type: Number,
                default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
            }
        },
        methods: {
            add2customercenter() {
                if (this.betIds.length == 0) {
                    this.$swal({
                        title: '베팅내역을 선택해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$router.push({
                    path: '/front/customercenter',
                    query: {sbid: this.betIds.join(","), t: new Date().getTime()}
                })
            },
            deleteAllBetInfo() {
                this.$swal({
                    title: '전체 베팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllBet().then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            checkBetId(id) {
                let index = this.betIds.findIndex((item) => {
                    return item === id
                });
                if (index === -1) {
                    this.betIds.push(id)
                } else {
                    this.betIds.splice(index, 1)
                }
                this.$emit('betInfoChecked', this.betIds)
            },
            cancelBet(id) {
                this.$swal({
                    title: '베팅내역을 취소하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        cancelBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })


            },
            deleteBet() {
                if (this.betIds.length == 0) {
                    this.$swal({
                        title: '베팅내역을 선택해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                this.$swal({
                    title: '선택하신 베팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        let idsString = this.betIds.join(',');
                        console.log(idsString)
                        deleteBet({idsString:idsString}).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
.match_warpper .info .league {
  height: 4.3rem;
  align-items: flex-end;
}
</style>